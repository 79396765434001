import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledCta = styled.section``

const CtaImage = styled(Image)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    width: 175px;
    height: 200px;
  }

  @media (max-width: 991px) {
    width: 125px;
    height: 150px;
  }
`

const CtaContent = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size.l};

    @media (max-width: 575px) {
      text-align: center;
    }
  }

  & p {
    line-height: ${(props) => props.theme.font.size.xm};
    margin: 1.5rem 0;
  }

  @media (max-width: 575px) {
    text-align: center;
  }
`

interface CtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const Cta: React.FC<CtaProps> = ({ fields }) => (
  <StyledCta className="my-1 mb-lg-5">
    <div className="container pb-5">
      <div className="row">
        <div className="col-sm-5 col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-center flex-grow">
          <CtaImage image={fields.image} />
        </div>
        <div className="col-sm-7 col-lg-6 flex-grow d-flex align-items-center mt-4 mt-sm-0">
          <CtaContent className="w-100" content={fields.description} />
        </div>
      </div>
    </div>
  </StyledCta>
)

export default Cta
